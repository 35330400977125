'use strict';

// var $ = require('jquery');
// $.noConflict();
// global.jQuery = require('jquery');

function easeOutQuint(t, b, c, d) {
    return c * (-Math.pow(2, -10 * t / d) + 1) + b;
}

function progress(t, b, c, d, width) {

    var time = Date.now() - t;

    setTimeout(function () {

        var newwidth = easeOutQuint(time, b, c, d);

        $('.progress-bar').css('width', newwidth + '%');

        if (parseInt((100 * parseFloat($('.progress-bar').css('width')) / parseFloat($('.progress-bar').parent().css('width')))) < 10) {
            $('.progress-bar').css('background-color', '#ff2807');
        } else if (parseInt((100 * parseFloat($('.progress-bar').css('width')) / parseFloat($('.progress-bar').parent().css('width')))) < 50) {
            $('.progress-bar').css('background-color', '#FFCA06');
        }

        progress(t, b, c, d, newwidth);

    }, 100);
}

function checkFocus() {
    $('.inputControl').each(function() {
        if(!isFieldEmpty($(this))) {
            $(this).next().addClass('inputFocused');
        }
    });
}

function detectAutofill (element) {
  return window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button';

}

$(document).ready(function () {
    var $ = window.jQuery;
    var App = require('./lib/App');


    $(document).on('focus click', '#device', function () {
        $(this).attr('type', 'text');
    });

    $(document).on('blur', '#device', function () {
        $(this).attr('type', 'password');
    });

    setTimeout(function() {
        if(detectAutofill(document.getElementById('password'))) {
            $('#password').next().addClass('inputFocused');
        }
        if(detectAutofill(document.getElementById('username'))) {
            $('#username').next().addClass('inputFocused');
        }
    }, 200);
    // Set cookie for language

    var app = new App();

    var updateFormInfo = function (form) {
        var children = $(form.data('error-element')) && $(form.data('error-element')).children()[0];
        if (children && children.childElementCount > 0) {
            $(form.data('error-element')).show();
        }

        // @TODO: Call AJAX, to get new form
        var cancelBtn = $('#cancelBtn');
        cancelBtn.on('click', function () {
            return window.location.reload();
        });

        $('.inputControl').on('focusin', function (e){
            $(this).next().addClass('inputFocused');
        });
        $('.inputControl').on('focusout', function (e){
            if(!$(this).val().length) {
                $(this).next().removeClass('inputFocused');
            }
        });

        // Disable fields according to steps
        if ($('#code', form).length > 0 || $('#k2b-challenge', form).length > 0) {
            $('#code', form).focus();
            cancelBtn.show();
        } else if ($('#device', form).length > 0) {
            $('#device', form).focus();
            cancelBtn.show();
        } else if (isFieldEmpty($('#username', form)) && isFieldEmpty($('#password', form))) {
            $('#username').next().addClass('inputFocused');
            $('#username').focus();
        } else if (!isFieldEmpty($('#username', form))) {
            $('#username').next().addClass('inputFocused');
            $('#password').next().addClass('inputFocused');
            $('#password').focus();
        }

        checkFocus();

        // Progress bar for SMS challenge
        if ($('.progress-bar').length) {
            var resendSmsBtn = $('.btn-resend');

            var timerLength = parseInt($('.progress-bar').attr('aria-valuemax'));
            var width = 100;

            progress(Date.now(), width, 0.8 - width, timerLength, 100);

            window.resendShowTimeout = setTimeout(function () {
                resendSmsBtn.show();
            }, resendSmsBtn.data('appear'));
        }
    };

    if ($('#loginForm')) {
        updateFormInfo($('#loginForm'));
    }

    var paymentFormSelector = $('#paymentForm');
    if (paymentFormSelector.length) {
        paymentFormSelector.data('ajax', 'ajax');
        paymentFormSelector.data('error-element', '#errorMessages');
        paymentFormSelector.data('append-element', '#confirmationField');

        updateFormInfo(paymentFormSelector);

        $('#confirmationType').change(function () {
            $('#paymentForm').submit();
        });
    }

    app.on('append', function (form) {
        updateFormInfo(form);
    });

    app.on('error', function (form) {
        if ($('#device', form).length > 0 && !$('#device', form).attr('disabled')) {
            $('#password', form).prop('disabled', false);
            $('#device', form).focus();
        }
    });
});

function isFieldEmpty(field) {
    return !field.val() || field.val() === '';
}
