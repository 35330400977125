/*eslint-disable func-style*/
'use strict';

//var $ = require('jquery');
var $ = window.jQuery;
var loginTimeoutInterval;

/**
 * Project: gatekeeper
 * File: App
 * Date & Time: 08.03.2017 14:02
 * Created by Deniss Perepelicins <me@rigaden.me>.
 */

var App = function App() {
    if (!(this instanceof App)) {
        return new App();
    }

    $.ajaxSetup({cache: false});
    this.eventListeners = {};
    this.log = console.log.bind(console);

    var self = this;

    this.phrases = {};
    $.getJSON('/translation.json', function (data) {
        self.phrases = data;
    });

    $('form[data-ajax]').on('submit', function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        var errors = [];
        var form = $(this);

        if ($('#username').length && $('#username').val().trim() === '') {
            errors.push('CIF required');
        }

        var password = $('#password');
        if (password.length && password.val() === '') {
            if (password.hasClass('digipass')) {
                errors.push('Digipass code required');
            } else {
                errors.push('Password required');
            }
        }

        if ($('#code').length && $('#code').val() === '') {
            if ($('#code.sms-code').length) {
                errors.push('SMS code required');
            } else {
                errors.push('Digipass code required');
            }
        } else if ($('#code').length && $('#code').val().length < 6) {
            if ($('#code.sms-code').length) {
                errors.push('ERR15');
            }
        }

        if ($('#device').length && $('#device').val() === '' &&
            /^\d{6}$/.test($('#password').val())) {
            errors.push('ERR16');
        } else if ($('#device').length && /[^0-9 -]/g.test($('#device').val()) &&
            /^\d{6}$/.test($('#password').val())) {
            errors.push('ERR24');
        } else if ($('#device').length && $('#device').val() === '') {
            errors.push('ERR17');
        } else if ($('#device').length && $('#device').val().length < 4) {
            errors.push('ERR18');
        }

        if (errors.length > 0) {
            return self.frontError(form, errors);
        }

        var continueBtn = $('#loginDecider');
        window.inAjax = true;
        continueBtn.prop('disabled', true);
        continueBtn.attr('value', self.phrases['Wait']);
        continueBtn.addClass('loading');
        if ($('#code').length) {
            if (window.resendShowTimeout) {
                clearTimeout(window.resendShowTimeout);
            }
            $('.btn-resend').hide();
            $('.progress-bar').hide();
        }


        self.emit('submit', form);

        var fields = form.serializeArray().reduce(function (obj, fld) {
            obj[fld.name] = fld.value;
            return obj;
        }, {});

        self.frontError(form, {});

        var target = form.attr('action') && form.attr('action').trim();
        if (!(target && target.length > 0)) {
            target = window.location.href;
        }


        $.post(target, fields, function (data, textStatus) {
            if (textStatus !== 'success') {
                return self.log('Error during AJAX request: ', textStatus, data);
            }

            self.handleResponse(form, data);
        }, 'json');


    });

    var resendCount = 0;

    $(document).on('click', '.btn-resend', function () {

        $(this).addClass('disabled');
        $(this).attr('disabled', 'disabled');
        var success = function (result) {
            if (result.error) {
                resendCount = 0;
                $(this).removeClass('disabled');
                $(this).attr('disabled', false);
            }
        };

        var fields = {};

        fields.resend = true;
        if ($('#username').length && $('#username').val()) {
            fields.username = $('#username').val();
            fields.password = $('#password').val();
        }
        // Send mobile number
        if (resendCount === 0) {
            resendCount++;
            $.post('', fields, success, 'json');
        }
    });

};


App.prototype.on = function (event, callback) {
    var listeners = this.eventListeners[event] || (this.eventListeners[event] = []);
    listeners.push(callback);
};

App.prototype.emit = function () {
    var args = [].slice.call(arguments);
    var event = args.shift();

    if (this.eventListeners[event] && this.eventListeners[event].length > 0) {
        for (var i = 0; i < this.eventListeners[event].length; i++) {
            try {
                this.eventListeners[event][i].apply(this, args);
            } catch (error) {
                this.log('Error calling listener on event ' + event, error);
            }
        }
    }
    // We can save emitted data, to keep it for first listener
};

App.prototype.handleResponse = function (form, data) {
    if (!data) {
        return;
    }

    if (data.redirect) {
        window.location = data.redirect;
        return;
    }

    if (data.timeleft) {
        this.loginTimeout(form, data.timeleft);
    }

    if (data.pushUrl) {
        history.pushState(null, null, data.pushUrl);
    }

    if (data.error) {
        this.frontError(form, data.error);
    }

    if (data.append) {
        $(form.data('append-element')).html(data.append);
        this.emit('append', form);
    }
};

App.prototype.frontError = function (form, errors) {
    // Hide errors
    var errorBlock = $(form.data('error-element'));
    errorBlock.hide();
    var errorList = $('ul', errorBlock).html('');

    if (errors && errors.length > 0) {
        var self = this;
        errors.forEach(function (error) {
            var li = document.createElement('li');
            li.innerText = self.phrases[error] || error;

            errorList.append(li);
        });

        if ($('#code').length && $('#code').val() !== '') {
            $('#code').val('');
        }

        if ($('#password').length && $('#password').val() !== '' && /^\d{6}$/.test($('#password').val())) {
            if ($('#device').length && $('#device').val() !== '') {
                $('#device').val('');
            } else if (!$('#device').length) {
                $('#password').val('');
            }
        }

        errorBlock.show();
        self.emit('error', form);
    }
};

App.prototype.getTimeoutMsg = function (time) {
    var msg;
    var minutes = Math.ceil(time / 60);
    if (time === 1) {
        msg = 'timeout_less_than_minute';
    } else if (time >= 2 && time <= 4) {
        msg = 'timeout_minute_1';
    } else if (time >= 5 && time <= 60) {
        msg = 'timeout_minute_2';
    } else if (minutes >= 2 && minutes <= 4) {
        msg = 'timeout_hour_1';
    } else {
        msg = 'timeout_hour_2';
    }

    msg = this.phrases.timeout_Head + this.phrases[msg] + this.phrases.timeout_Tail;
    return msg.replace('INSERT_TIME', (time > 60) ? minutes : time);
};

App.prototype.loginTimeout = function (form, timeLeft) {
    var self = this;
    document.activeElement.blur();
    $('#loginDecider').hide(0);
    $('#cancelBtn').show();
    $('#device').addClass('disabled').attr('disabled', 'disabled');
    setInterval(function tick() {
        if (timeLeft <= 0) {
            window.location.reload();
        } else {
            self.frontError(form, [self.getTimeoutMsg(timeLeft)]);
            timeLeft--;
            return tick;
        }
    }(), 60000);
};

module.exports = App;
